var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('footer',{staticClass:"footer-pc"},[_c('div',{staticClass:"container footer-content"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"jiyin-logo",on:{"click":function($event){return _vm.go('/home')}}},[_c('img',{attrs:{"src":require("../assets/jiyin.svg"),"alt":"jiyin"}})]),_vm._m(0)]),_c('div',{staticClass:"content-mid"},[_c('div',{staticClass:"footer-menu"},[_c('p',{staticClass:"router-text bolder"},[_vm._v("联系我们")]),_c('router-link',{staticClass:"router-text",attrs:{"to":"/service?location=afterSales"}},[_vm._v("联系客服")]),_c('p',{staticClass:"router-text",attrs:{"onclick":"qimoChatClick()"}},[_vm._v("在线咨询")])],1),_vm._m(1),_c('div',{staticClass:"footer-menu"},[_c('p',{staticClass:"router-text bolder"},[_vm._v("照片打印机经销商")]),_c('router-link',{staticClass:"router-text",attrs:{"to":"/store/photo"}},[_vm._v("线下门店")]),_c('router-link',{staticClass:"router-text",attrs:{"to":"/store/photo-online"}},[_vm._v("线上经销商")])],1),_c('div',{staticClass:"footer-menu"},[_c('p',{staticClass:"router-text bolder"},[_vm._v("激光打印机经销商")]),_c('router-link',{staticClass:"router-text",attrs:{"to":"/store/laser"}},[_vm._v("经销商")])],1)]),_c('div',{staticClass:"content-bot"},[_c('span',[_vm._v("上海汉图科技有限公司Copyright Hannto Technology Inc.")]),_c('span',{staticClass:"like-link",on:{"click":function($event){return _vm.goOutside('https://beian.miit.gov.cn/')}}},[_vm._v("沪ICP备17014357号-6")]),_c('img',{attrs:{"src":require("../assets/gongan.png"),"alt":"guohui"}}),_c('span',{staticClass:"like-link",on:{"click":function($event){return _vm.goOutside(
              'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019149'
            )}}},[_vm._v("沪公网安备 31011502019149号")])])]),_c('div',{staticClass:"full-line"})]),_c('footer',{staticClass:"footer-mobile"},[_c('div',{staticClass:"back-top-wrap"},[_c('div',{staticClass:"back-top-item",on:{"click":function($event){return _vm.backTop()}}},[_c('span',{staticClass:"menu-text",staticStyle:{"margin-right":"6px"}},[_vm._v("回到顶部")]),_vm._m(2)])]),_c('FooterMenuItem',{attrs:{"title":"联系我们","children":_vm.contact}}),_c('FooterMenuItem',{attrs:{"title":"商务合作","children":_vm.bussiness}}),_c('FooterMenuItem',{attrs:{"title":"照片打印机经销商","children":_vm.photo}}),_c('FooterMenuItem',{attrs:{"title":"激光打印机经销商","children":_vm.laser}}),_c('div',{staticClass:"media-list"},[_c('div',{staticClass:"normal-img",staticStyle:{"width":"12px"},on:{"click":function($event){return _vm.goOutside(
            'https://m.douyin.com/share/user/MS4wLjABAAAAPGNRPgJ-UbZB0PDmWGPkftw4yPtbZ6ZfhPRvRj_Z_ho'
          )}}},[_c('img',{attrs:{"src":require("../assets/tiktok.svg"),"alt":"抖音"}})]),_c('div',{staticClass:"normal-img",staticStyle:{"width":"18px"},on:{"click":function($event){return _vm.goOutside('https://weixin.qq.com/r/4z8-J_zEZbEarahE92rx')}}},[_c('img',{attrs:{"src":require("../assets/wechat.svg"),"alt":"微信"}})]),_c('div',{staticClass:"normal-img",staticStyle:{"width":"18px"},on:{"click":function($event){return _vm.goOutside('https://weibo.com/u/6376460799')}}},[_c('img',{attrs:{"src":require("../assets/weibo.svg"),"alt":"微博"}})]),_c('div',{staticClass:"normal-img",staticStyle:{"width":"22px"},on:{"click":function($event){return _vm.goOutside(
            'https://www.xiaohongshu.com/user/profile/5bcd512cea69df0001f36e38?xhsshare=CopyLink&appuid=5bcd512cea69df0001f36e38&apptime=1662023271'
          )}}},[_c('img',{attrs:{"src":require("../assets/redbook.svg"),"alt":"小红书"}})])]),_c('div',{staticClass:"info-text"},[_vm._v(" 上海汉图科技有限公司Copyright Hannto Technology Inc. ")]),_c('div',{staticClass:"info-text",on:{"click":function($event){return _vm.goOutside('https://beian.miit.gov.cn/')}}},[_vm._v(" 沪ICP备17014357号-6 ")]),_c('div',{staticClass:"info-text",staticStyle:{"display":"flex","align-items":"center"},on:{"click":function($event){return _vm.goOutside(
          'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019149'
        )}}},[_c('img',{staticStyle:{"margin-right":"4px"},attrs:{"src":require("../assets/gongan.png"),"alt":"国徽"}}),_c('span',[_vm._v("沪公网安备 31011502019149号")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-list"},[_c('div',{staticClass:"media-item"},[_c('img',{staticClass:"normal-img",staticStyle:{"width":"22px"},attrs:{"src":require("../assets/tiktok.svg"),"alt":"tiktok.svg"}}),_c('img',{staticClass:"hover-img",attrs:{"src":require("../assets/tiktok_circle.svg"),"alt":"tiktok.svg"}}),_c('div',{staticClass:"code-wrap"},[_c('img',{staticClass:"code-img",attrs:{"src":require("../assets/tiktok.jpg"),"alt":""}}),_c('i',{staticClass:"code-triangle"})])]),_c('div',{staticClass:"media-item"},[_c('img',{staticClass:"normal-img",staticStyle:{"width":"31px"},attrs:{"src":require("../assets/wechat.svg"),"alt":"wechat.svg"}}),_c('img',{staticClass:"hover-img",attrs:{"src":require("../assets/wechat_circle.svg"),"alt":"wechat.svg"}}),_c('div',{staticClass:"code-wrap"},[_c('img',{staticClass:"code-img",attrs:{"src":require("../assets/wechat.jpg"),"alt":""}}),_c('i',{staticClass:"code-triangle"})])]),_c('div',{staticClass:"media-item"},[_c('img',{staticClass:"normal-img",staticStyle:{"width":"31px"},attrs:{"src":require("../assets/weibo.svg"),"alt":"weibo.svg"}}),_c('img',{staticClass:"hover-img",attrs:{"src":require("../assets/weibo_circle.svg"),"alt":"weibo.svg"}}),_c('div',{staticClass:"code-wrap"},[_c('img',{staticClass:"code-img",attrs:{"src":require("../assets/weibo.jpg"),"alt":""}}),_c('i',{staticClass:"code-triangle"})])]),_c('div',{staticClass:"media-item"},[_c('img',{staticClass:"normal-img",staticStyle:{"width":"41px"},attrs:{"src":require("../assets/redbook.svg"),"alt":"redbook.svg"}}),_c('img',{staticClass:"hover-img",attrs:{"src":require("../assets/redbook_circle.svg"),"alt":"redbook.svg"}}),_c('div',{staticClass:"code-wrap"},[_c('img',{staticClass:"code-img",attrs:{"src":require("../assets/red_book.jpg"),"alt":""}}),_c('i',{staticClass:"code-triangle"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-menu"},[_c('p',{staticClass:"router-text bolder"},[_vm._v("商务合作")]),_c('a',{staticClass:"router-text",attrs:{"href":"mailto:business@hannto.com","title":"business@hannto.com"}},[_vm._v("商务合作")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"up-icon"},[_c('img',{attrs:{"src":require("../assets/up.svg"),"alt":"up"}})])
}]

export { render, staticRenderFns }