import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const Home = () => import("@/views/home");
const ProcudtPhoto = () => import("@/views/product/photo");
const ProcudtLaser = () => import("@/views/product/laser");
const ProcudtLaserDetail = () => import("@/views/product/laser-detail");
const ServiceIndex = () => import("@/views/service/index");
const ServiceDetail = () => import("@/views/service/detail");
const ServiceFaq = () => import("@/views/service/faq");
const ServiceVideo = () => import("@/views/service/video");
const About = () => import("@/views/about");
const StoreLaser = () => import("@/views/store/laser");
const StorePhoto = () => import("@/views/store/photo");
const StorePhotoOnline = () => import("@/views/store/photo-online");

export default new Router({
  mode: "history",
  routes: [
    {
      path: "",
      redirect: "/home",
    },
    {
      path: "/home",
      component: Home,
    },
    {
      path: "/photo",
      component: ProcudtPhoto,
    },
    {
      path: "/laser",
      component: ProcudtLaser,
    },
    {
      path: "/laser/:model",
      component: ProcudtLaserDetail,
    },
    {
      path: "/service",
      component: ServiceIndex,
    },
    {
      path: "/service/DHP511",
      component: ServiceDetail,
    },
    {
      path: "/service/DMP100",
      component: ServiceDetail,
    },
    {
      path: "/service/ZPP110",
      component: ServiceDetail,
    },
    {
      path: "/service/XP356DNL",
      component: ServiceDetail,
    },
    {
      path: "/service/faq",
      component: ServiceFaq,
    },
    {
      path: "/service/video",
      component: ServiceVideo,
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/store",
      redirect: "/store/laser",
    },
    {
      path: "/store/laser",
      component: StoreLaser,
    },
    {
      path: "/store/photo",
      component: StorePhoto,
    },
    {
      path: "/store/photo-online",
      component: StorePhotoOnline,
    },
    {
      path: "*",
      redirect: "/home",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
