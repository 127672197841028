<template>
  <div>
    <div class="footer-menu-wrap">
      <div
        class="footer-menu-item"
        :style="!fold && { borderBottom: 0 }"
        @click="fold = !fold"
      >
        <span class="menu-text" style="margin-right: 6px">{{ title }}</span>
        <div v-if="fold" class="menu-icon">
          <img src="../assets/plus.svg" alt="plus" />
        </div>
        <div v-else class="menu-icon">
          <img src="../assets/minus.svg" alt="minus" />
        </div>
      </div>
    </div>
    <div class="footer-hidden-menu-wrap">
      <div
        :class="
          fold ? 'footer-hidden-menu hidden' : 'footer-hidden-menu visible'
        "
      >
        <div
          v-for="item in children"
          :key="item.name"
          class="footer-hidden-menu-item"
          @click="go(item.path)"
        >
          <span class="menu-text">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    children: [],
  },
  data() {
    return {
      fold: true,
    };
  },
  methods: {
    go(path) {
      if (path === "qimo") {
        window.qimoChatClick();
        return;
      }
      if (path === "email") {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = "mailto:business@hannto.com";
        a.click();
        a.remove();
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less" scoped>
.footer-menu-wrap {
  padding: 0 20px;
  .footer-menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
    .menu-text {
      font-size: 12px;
      color: #191919;
      font-weight: 400;
    }
    .menu-icon {
      width: 10px;
      img {
        width: 100%;
        display: block;
      }
    }
  }
}
.footer-hidden-menu-wrap {
  padding: 0 20px;
  background-color: #ededed;
  .footer-hidden-menu {
    overflow: hidden;
    transition: max-height 0.2s;
    &.hidden {
      max-height: 0;
    }
    &.visible {
      max-height: 100px;
    }
    .footer-hidden-menu-item {
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d1d1d1;
      &:last-child {
        border-bottom: 0;
      }
      .menu-text {
        font-size: 12px;
        color: #191919;
        font-weight: 400;
      }
    }
  }
}
</style>
