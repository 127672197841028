<template>
  <div>
    <header class="header-pc">
      <div class="container header-content">
        <div class="left-wrap">
          <div class="logo-wrap" @click="go('/home')">
            <img src="../assets/jiyin.svg" alt="jiyin" />
          </div>
        </div>
        <div class="mid-wrap">
          <router-link class="router-link" to="/home">
            <span class="router-text">首页</span>
            <span class="router-bar"></span>
          </router-link>
          <router-link class="router-link" to="/photo">
            <span class="router-text">照片打印机</span>
            <span class="router-bar"></span>
          </router-link>
          <router-link class="router-link" to="/laser">
            <span class="router-text">激光打印机</span>
            <span class="router-bar"></span>
          </router-link>
          <router-link class="router-link" to="/service">
            <span class="router-text">服务与支持</span>
            <span class="router-bar"></span>
          </router-link>
          <router-link class="router-link" to="/about">
            <span class="router-text">关于我们</span>
            <span class="router-bar"></span>
          </router-link>
        </div>
        <div class="right-wrap">
          <div class="store-btn">
            <span></span>
            <span class="store-text">在线商城</span>
            <div class="store-menu">
              <ul class="store-list">
                <li
                  class="store-item"
                  @click="goOutside('https://xprint.tmall.com/')"
                >
                  <img src="../assets/tm.svg" alt="tm.svg" />
                  <span>天猫旗舰店</span>
                </li>
                <li
                  class="store-item"
                  @click="
                    goOutside('https://mall.jd.com/index-1000305542.html')
                  "
                >
                  <img src="../assets/jd.svg" alt="jd.svg" />
                  <span>京东旗舰店</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="header-mobile">
      <div class="icon-wrap" @click="toogleMenu()">
        <img
          v-if="menuVisible"
          class="close-icon"
          src="../assets/close.svg"
          alt=""
        />
        <img v-else class="menu-icon" src="../assets/menu.svg" alt="" />
      </div>
      <div class="jiyin-logo">
        <img src="../assets/jiyin.svg" alt="" />
      </div>
      <div class="icon-wrap" @click="toogleStore()">
        <img
          v-if="storeVisible"
          class="close-icon"
          src="../assets/close.svg"
          alt=""
        />
        <img
          v-else
          class="store-icon"
          src="../assets/package-gray.png"
          alt=""
        />
      </div>
      <div class="menu-modal" :class="menuVisible ? 'visible' : 'hidden'">
        <div class="menu-item">
          <router-link
            class="router-text"
            to="/home"
            @click.native="menuVisible = false"
            >首页</router-link
          >
        </div>
        <div class="menu-item">
          <router-link
            class="router-text"
            to="/photo"
            @click.native="menuVisible = false"
            >照片打印机</router-link
          >
        </div>
        <div class="menu-item">
          <router-link
            class="router-text"
            to="/laser"
            @click.native="menuVisible = false"
            >激光打印机</router-link
          >
        </div>
        <div class="menu-item">
          <router-link
            class="router-text"
            to="/service"
            @click.native="menuVisible = false"
            >服务与支持</router-link
          >
        </div>
        <div class="menu-item">
          <router-link
            class="router-text"
            to="/about"
            @click.native="menuVisible = false"
            >关于我们</router-link
          >
        </div>
      </div>
      <div class="menu-modal" :class="storeVisible ? 'visible' : 'hidden'">
        <div
          class="menu-item"
          @click="
            storeVisible = false;
            goOutside('https://xprint.tmall.com/');
          "
        >
          <p class="router-text">天猫旗舰店</p>
        </div>
        <div
          class="menu-item"
          @click="
            storeVisible = false;
            goOutside('https://mall.jd.com/index-1000305542.html');
          "
        >
          <p class="router-text">京东旗舰店</p>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuVisible: false,
      storeVisible: false,
    };
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    goOutside(path) {
      window.open(path);
    },
    toogleMenu() {
      this.storeVisible = false;
      this.menuVisible = !this.menuVisible;
    },
    toogleStore() {
      this.menuVisible = false;
      this.storeVisible = !this.storeVisible;
    },
  },
};
</script>
<style lang="less" scoped>
.header-pc {
  width: 100%;
  height: 96px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  border-bottom: 1px solid #e6e6e6;
  background-color: var(--jy-color-white);
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-wrap {
      width: 7.1875vw;
      min-width: 7.1875vw;
      display: flex;
      align-items: center;
      margin-right: 3vw;
      .logo-wrap {
        width: 7.1875vw;
        cursor: pointer;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .mid-wrap {
      flex: 1;
      display: flex;
      align-items: center;
      .router-link {
        min-width: 100px;
        flex: 1;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:hover {
          .router-text {
            opacity: 0.6;
          }
        }
        &.router-link-active {
          pointer-events: none;
          .router-text {
            opacity: 1 !important;
          }
          .router-bar {
            display: block;
          }
        }
        .router-text {
          font-size: var(--jy-font-size);
          color: var(--jy-color-grayer);
          font-weight: 400;
        }
        .router-bar {
          width: 100px;
          height: 3px;
          display: none;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -50px;
          background-color: #17d5d8;
        }
      }
    }
    .right-wrap {
      .store-btn {
        width: 136px;
        height: 42px;
        min-width: 136px;
        margin-left: 3vw;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        border-radius: 21px;
        background-color: #252c36;
        &:hover {
          .store-menu {
            display: block;
          }
        }
        .store-text {
          font-size: var(--jy-font-size);
          color: var(--jy-color-white);
          font-weight: 400;
        }
        .store-menu {
          width: 154px;
          min-width: 154px;
          display: none;
          position: absolute;
          left: -9px;
          top: 42px;
          padding-top: 28px;
          .store-list {
            padding: 18px 16px;
            border-radius: 16px;
            background-color: var(--jy-color-white);
            .store-item {
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              cursor: pointer;
              &:last-child {
                margin-bottom: 0;
              }
              &:hover {
                opacity: 0.6;
              }
              img {
                width: 24px;
                height: 24px;
                margin-right: 12px;
              }
              span {
                font-size: var(--jy-font-size);
                color: var(--jy-color-black);
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
.header-mobile {
  width: 100%;
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 0 12px;
  display: none;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  background-color: #ffffff;
  .icon-wrap {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      opacity: 0.6;
    }
    .menu-icon {
      width: 18px;
      display: block;
    }
    .store-icon {
      width: 16px;
      display: block;
    }
    .close-icon {
      width: 16px;
      display: block;
    }
  }
  .jiyin-logo {
    width: 76px;
    &:active {
      opacity: 0.6;
    }
    img {
      width: 100%;
      display: block;
    }
  }
  .menu-modal {
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 44px;
    left: 0;
    z-index: 1000;
    padding: 0 20px;
    transition: all 0.3s;
    background-color: #ffffff;
    &.hidden {
      max-height: 0;
      visibility: hidden;
    }
    &.visible {
      max-height: 300px;
      visibility: visible;
    }
    .menu-item {
      height: 56px;
      border-bottom: 1px solid #d1d1d1;
      &:active {
        opacity: 0.6;
      }
      &:last-child {
        border-bottom: 0;
      }
      .router-text {
        font-size: 13px;
        color: #262626;
        font-weight: 400;
        line-height: 56px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .header-pc {
    display: none;
  }
  .header-mobile {
    display: flex;
  }
}
</style>
